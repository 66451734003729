<template>
  <v-layout column full-width>
    <v-layout wrap align-end>
      <v-flex grow v-if="uploading" class="ml-4 mr-6">
        <v-layout column>
          <v-flex>
            <div class="text-subtitle-1 black--text">
              Wysyłanie {{ filename }}: {{ progress }}%
            </div>
          </v-flex>
          <v-progress-linear
            v-if="uploading"
            v-model="progress"
          ></v-progress-linear>
        </v-layout>
      </v-flex>
      <v-layout>
        <UploadButton
          v-if="!uploading"
          @file-update="(items) => onFilesUpload(items)"
          noTitleUpdate
          ref="uploadButton"
          block
          :class="[
            'font-weight-bold',
            small
              ? 'small lab-style-small btn-style-small'
              : 'lab-style btn-style',
          ]"
          labelClass="elevation-0 lab-style"
          :title="buttonTitle"
          label="Dodaj pliki"
          multiple
        ></UploadButton>
      </v-layout>
    </v-layout>
    <ConfirmDialog
      maxWidth="700px"
      :persistent="true"
      :dontHideOnPositiveAction="true"
      :value.sync="showMultipleImagesPopup"
      v-if="showMultipleImagesPopup"
      :loading="uploading"
      :disabledPositiveButton="uploading"
      positiveButtonText="Wyślij"
      :disabledNegativeButton="uploading"
      :positiveAction="submitFiles"
      :negativeAction="clearMultipleFilePopup"
      title="Dodaj wiele plików"
    >
      <v-layout wrap class="justify-space-around" slot="content">
        <v-flex
          xs12
          sm6
          md4
          lg3
          class="ma-1 rounded-image"
          v-for="item in files"
          :key="item.image"
        >
          <ImageWithDescription
            :editButtons="true"
            :hideDescription="false"
            :hideDownloadButtons="true"
            :hideEditButtons="false"
            @saveAction="
              (value) => {
                item = value;
              }
            "
            :value="item"
            @deleteFile="deleteFile"
          >
          </ImageWithDescription>
        </v-flex>
      </v-layout>
    </ConfirmDialog>
    <EditImagePopup
      v-if="showEditImagePopup"
      :showPopup="showEditImagePopup"
      :value="file"
      :uploading.sync="uploading"
      @cancel="clearEditPopup"
      @saveEditedImage="submitFile"
    >
    </EditImagePopup>
  </v-layout>
</template>

<script>
import axios from "axios";

import UploadButton from "vuetify-upload-button";
import PatientService from "@/services/patient.service";
import FileHelper from "@/services/file.helper";
import {
  IMAGE,
  PDF,
  DOCUMENT,
  UNKNOWN,
} from "@/components/ImageWithDescription";

const CancelToken = axios.CancelToken;
let cancel = undefined;

export default {
  data() {
    return {
      showMultipleImagesPopup: false,
      showEditImagePopup: false,
      filename: "",
      progress: 0,
      uploading: false,
      files: [],
      file: null,
    };
  },
  props: {
    buttonTitle: {
      default: "Dodaj pliki",
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    UploadButton,
    ImageWithDescription: () => import("@/components/ImageWithDescription"),
    ConfirmDialog: () => import("@/components/popups/ConfirmDialog"),
    EditImagePopup: () => import("@/components/popups/EditImagePopup"),
  },
  methods: {
    getTypeOfFile(file) {
      switch (file.type.split("/")[0]) {
        case "application":
          return file.type == "application/pdf" ? PDF : DOCUMENT;
        case "image":
          return IMAGE;
        default:
          return UNKNOWN;
      }
    },
    async onFilesUpload(files) {
      let filesData = [];
      if (files) {
        filesData = Object.values(files).map((file) => {
          return this.catchFiles(file).then((base64) => {
            const size = Math.floor(base64.length * (3 / 4) - 2);
            return {
              image: base64,
              title: file.name,
              human_size: FileHelper.humanSize(size, true),
              size: size,
              type: this.getTypeOfFile(file),
            };
          });
        });
      }
      if (files) {
        if (files.length > 1) {
          filesData.map((item) => {
            return item.then((data) => {
              this.files.push(data);
            });
          });
          this.showMultipleImagesPopup = true;
        } else if (files.length == 1) {
          filesData[0].then((data) => {
            this.file = data;
            if (data.type == IMAGE) {
              this.showEditImagePopup = true;
            } else {
              this.submitFile(data);
            }
          });
        }
      }
    },
    catchFiles(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          if (this.getTypeOfFile(file) === 0) {
            FileHelper.resizeImage({ file: file, maxSize: 1920 }).then(
              (resizedImage) => {
                resolve(resizedImage);
              }
            );
          } else {
            resolve(reader.result);
          }
        };
        reader.onerror = (error) => reject(error);
      });
    },
    deleteFile(file) {
      const index = this.files.findIndex((s) => s.title === file.title);
      this.files.splice(index, 1);
      if (this.files.length == 0) {
        this.clear();
      }
    },
    submitFile(fileData) {
      this.uploading = true;
      fetch(fileData.image)
        .then((res) => res.blob())
        .then((blob) => {
          let formData = new FormData();
          this.filename = fileData.title;
          const newFile = new File([blob], this.filename);
          formData.append("file", newFile);
          formData.append("title", this.filename);
          formData.append("size", fileData.size);
          PatientService.uploadDocuments(
            formData,
            this.onUploadProgress,
            new CancelToken(function executor(c) {
              // An executor function receives a cancel function as a parameter
              cancel = c;
            })
          )
            .then(() => {
              if (this.files.length === 1) {
                this.uploading = false;
              }
              this.$emit("uploaded", true);
              this.deleteFile(fileData);
            })
            .catch((error) => {
              this.clear();
              this.$emit("uploaded", false);
            });
        });
    },
    submitFiles() {
      this.files.forEach((fileData, index) => {
        setTimeout(() => {
          this.submitFile(fileData);
        }, 500 * index);
      });
      this.$emit("uploaded", true);
    },
    onUploadProgress(progressEvent) {
      this.progress = parseInt(
        Math.round((progressEvent.loaded * 100) / progressEvent.total)
      );
    },
    clearEditPopup() {
      this.$refs.uploadButton.clear();
      this.showEditImagePopup = false;
    },
    clearMultipleFilePopup() {
      if (this.uploading === false) {
        this.$refs.uploadButton.clear();
        this.showMultipleImagesPopup = false;
        this.files = [];
        this.file = "";
      }
    },
    clear() {
      this.file = "";
      this.files = [];
      this.uploading = false;
      this.showMultipleImagesPopup = false;
      this.showEditImagePopup = false;
      this.filename = "";
      this.progress = 0;
    },
  },
};
</script>

<style scoped lang="scss">
.rounded-image {
  border-radius: 8px;
  border: none;
  overflow: hidden;
}
.btn-style {
  height: 3rem !important;
  width: 100%;
  background-color: white !important;
}
.btn-style-small {
  height: 2rem !important;
  width: 100%;
  background-color: white !important;
}
.btn-style ::v-deep .v-btn {
  background-color: white !important;
  color: var(--v-primary-base) !important;
  border: 1px solid var(--v-border-base) !important;
  height: 3rem !important;
}
.btn-style-small ::v-deep .v-btn {
  background-color: white !important;
  color: var(--v-primary-base) !important;
  border: 1px solid var(--v-border-base) !important;
  height: 2rem !important;
}
.btn-style-small ::v-deep .v-btn:hover {
  border: 1px solid var(--v-primary-base) !important;
}
.lab-style ::v-deep label {
  height: 3rem !important;
  width: 100%;
  border-radius: 0.5rem;
  font-weight: bold;
  font-size: 14px !important;
}
.lab-style-small ::v-deep label {
  height: 2rem !important;
  width: 100%;
  border-radius: 0.5rem;
  font-weight: bold;
  font-size: 12px !important;
}
</style>
