
const FileHelper = {
  resizeImage({ file, maxSize = false }) {
    const reader = new FileReader();
    const image = new Image();
    const canvas = document.createElement('canvas');

    const resize = () => {
      let { width, height } = image;

      if (maxSize !== false) {
        if (width > height) {
          if (width > maxSize) {
            height *= maxSize / width;
            width = maxSize;
          }
        } else if (height > maxSize) {
          width *= maxSize / height;
          height = maxSize;
        }
      }

      canvas.width = width;
      canvas.height = height;
      canvas.getContext('2d').drawImage(image, 0, 0, width, height);
      const dataUrl = canvas.toDataURL(file.type);
      return dataUrl;
    };

    return new Promise((ok, no) => {
      reader.onload = (readerEvent) => {
        image.onload = () => ok(resize());
        image.src = readerEvent.target.result;
      };

      reader.readAsDataURL(file);
    });
  },
  humanSize(size, si=false) {
    let u, b=size, t= si ? 1000 : 1024;
    ['', si?'k':'K', ...'MGTPEZY'].find(x=> (u=x, b/=t, b**2<1));
    return `${u ? (t*b).toFixed(1) : size} ${u}${!si && u ? 'i':''}B`;
  },
}

export default FileHelper
